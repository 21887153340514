<template>
  <div class="card">
    <header class="card-header">
      <div class="columns is-multiline card-header-title ">
        <div class="column is-12">
          <p class="modal-card-title">
            {{ titleCard }}
          </p>
        </div>
      </div>
    </header>
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-10">
          <div class="field control">
            <label class="label">Categoría</label>
            <input
              class="input"
              type="text"
              placeholder="Categoría"
              v-model="category"
              name="category"
              v-validate="'required'"
              data-vv-as="de la categoría"
            >
            <span v-show="vverrors.has('category')" class="help is-danger">{{ vverrors.first('category') }}</span>
          </div>
        </div>
        <div class="column is-10" v-if="!modeCreate">
          <label class="label">Fecha de creación</label>
          <p class="paragraph"> {{ formatDate(creationDate, 'DD/MM/YYYY') }} </p>
        </div>
        <div class="column is-10">
          <div class="is-flex is-align-items-center">
            <label class="label mr-5 mb-0">Estatus</label>
            <span>
              <input
                id="switchStatus"
                type="checkbox"
                name="switchStatusStatus"
                class="switch is-rounded is-small"
                v-model="status"
                :checked="status ? 'checked' : ''"
              >
              <label for="switchStatus"></label>
            </span>
          </div>
        </div>
        <div class="column is-half">
          <button
            class="button button_micro_dark_outlined is-fullwidth"
            @click="$emit('cancel-action')"
            :disabled="loading"
          >
            CANCELAR
          </button>
        </div>
        <div class="column is-half">
          <button
            class="button button_micro_dark is-fullwidth"
            :disabled="loading"
            :class="{ 'is-loading' : loading }"
            @click="setDataUpdateAction"
          >
            {{ modeCreate ? 'AGREGAR' : 'GUARDAR' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins/formats'

export default {
  name: 'CardCategorie',
  mixins: [mixins],
  data () {
    return {
      creationDate: this.dataCategory.created_at,
      status: this.dataCategory.active,
      category: this.dataCategory.name
    }
  },
  props: {
    titleCard: {
      type: String,
      default: null
    },
    modeCreate: {
      type: Boolean,
      default: false
    },
    dataCategory: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async setDataUpdateAction () {
      if (await this.$validator.validateAll()) {
        const dataUpdate = {
          categoryId: this.dataCategory.id,
          category: this.category,
          active: this.status
        }
        this.$emit('set-data-update', dataUpdate)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
    margin-top: 1rem;
  }
  .card-header {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    border-bottom: solid 1px #e8e8e8;
  }
</style>
